@font-face {
  font-family: Kontrapunkt;
  src: url(../fonts/Kontrapunkt.light.ttf);
}

@font-face {
  font-family: Kontrapunkt-Bold;
  src: url(../fonts/Kontrapunkt.bold.ttf);
}

html {
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
  font-family: Kontrapunkt;
  color: black;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  align-items: stretch;
}

aside {
  width: 250px;
  text-align: left;
}

.aside-logo {
  width: 170px;
  height: auto;
  margin: 40px;
  cursor: pointer;
}

nav {
  padding: 0;
  margin: 0 40px;
  font-size: 0.9rem;
}

#about-me {
  display: block;
  text-decoration: none;
  font-family: Kontrapunkt-Bold;
  margin-bottom: 2rem;
}

.nav-section-header {
  display: block;
  text-decoration: none;
  font-family: Kontrapunkt-Bold;
  margin-bottom: 0.5em;
}

.project-ul {
  list-style-type: none;
  margin-bottom: 2rem;
}

.project-ul a {
  display: block;
  text-decoration: none;
  margin-bottom: 0.5em;
}

.cv {
  margin-bottom: 1rem;
}

button {
  cursor: pointer;
  border-radius: 3px;
  color: white;
  background-color: #aaa;
  padding: 5px 6px 4px 6px;
  font-family: Kontrapunkt-Bold;
  text-decoration: none;
  border: 0;
  margin-right: 1rem;
}

.social-media-icon {
  width: 1rem;
  margin: 0.2rem;
}

#thumbnail-page {
  margin: 1rem 1rem 1rem 0;
}

.page ul {
  grid-gap: 1rem;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fit, 300px);
  justify-content: start;
  list-style-type: none;
  margin-bottom: 1rem;
}

.page ul li {
  position: relative;
}

.page ul .Rollover {
  position: absolute;
  width: 300px;
  height: 250px;
  text-align: center;
  color: white;
  opacity: 0;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  justify-content: center;
  top: 0;
}

.page ul .Rollover:hover {
  opacity: 1;
  cursor: pointer;
}

.page ul img {
  width: 300px;
  height: 250px;
}

.section {
  margin: 0;
  margin-bottom: 2rem;
}

.section:last-child {
  margin: 0;
  margin-bottom: 0;
}

header {
  width: auto;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-bottom: 1px solid black;
}

@media only screen and (max-width: 600px) {
  .App {
    flex-direction: column;
  }
  aside {
    width: 300px;
    margin: auto;
    margin-bottom: 0;
    text-align: left;
  }

  nav {
    margin: auto;
    width: 300px;
    margin-bottom: 40px;
  }
  .project-ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }

  .project-ul a {
    margin-right: 0.5rem;
  }

  #thumbnail-page {
    padding-bottom: 1rem;
    margin: 0rem 1rem 0rem 1rem;
  }
  #thumbnail-page ul {
    justify-content: center;
  }

  .nav-section-header {
    margin-bottom: 0.5em;
  }
  .section-header {
    text-align: center;
  }

  header {
    width: 300px;
    margin: auto;
    margin-bottom: 1rem;
  }
}

.mpu-text-block {
  width: 300px;
  height: auto;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 1rem;
}

.mpu-block {
  width: 300px;
  height: 250px;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 1rem;
}

.dooh-block {
  width: 300px;
  height: 533px;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 1rem;
}

/*.mpu-block:last-child {
  margin-right: 0;
}*/

.mpu-block img {
  width: 300px;
  height: 250px;
}

.mpu-text-block p {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #333;
  line-height: 1.3;
}

.mpu-text-block p:last-child {
  margin-bottom: 0;
}

iframe {
  border: none;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.iframe-300x250 {
  width: 300px;
  height: 250px;
}

.video-300x533 {
  width: 300px;
  height: 533px;
  border: 1px solid black;
}

.iframe-970x250 {
  width: 970px;
  height: 250px;
}

.iframe-300x600 {
  width: 300px;
  height: 600px;
}

.iframe-160x600 {
  width: 160px;
  height: 600px;
}

.iframe-728x90 {
  width: 728px;
  height: 90px;
}

.iframe-320x50 {
  width: 320px;
  height: 50px;
}

.iframe-300x50 {
  width: 300px;
  height: 50px;
}

.iframe-970x66 {
  width: 970px;
  height: 66px;
}

.iframe-960x378 {
  width: 960px;
  height: 378px;
}

.four-dooh-container {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-auto-rows: 533px;
  justify-content: start;
  margin: 1rem 1rem 1rem 0;
}

.page {
  width: 100%;
  margin: 1rem 1rem 1rem 0;
}

.main,
.broadsheet-main {
  position: relative;
}

#facebook-300x600,
#fifa19-300x250,
#fitbit-300x250,
#oracle-300x250,
#sky-160x600 {
  left: 0;
  top: 0;
  position: absolute;
}

#fifa19-970x250,
#fitbit-970x250,
#oracle-160x600 {
  left: 300px;
  top: 0;
  transform: translate(1rem, 0);
  position: absolute;
}

#fifa19-300x600,
#fitbit-300x600,
#oracle-300x600 {
  left: 0px;
  top: 250px;
  transform: translate(0, 1rem);
  position: absolute;
}

#fifa19-160x600,
#fitbit-728x90 {
  left: 300px;
  top: 250px;
  transform: translate(1rem, 1rem);
  position: absolute;
}

#facebook-300x250 {
  left: 300px;
  top: 0px;
  transform: translate(1rem, 0);
  position: absolute;
}

#facebook-728x90 {
  left: 300px;
  top: 250px;
  transform: translate(1rem, 1rem);
  position: absolute;
}

#fifa19-970x66 {
  left: 460px;
  top: 250px;
  transform: translate(2rem, 1rem);
  position: absolute;
}

#fifa19-728x90 {
  left: 460px;
  top: 316px;
  transform: translate(2rem, 2rem);
  position: absolute;
}

#fifa19-320x50 {
  left: 460px;
  top: 406px;
  transform: translate(2rem, 3rem);
  position: absolute;
}

#fitbit-300x50 {
  left: 300px;
  top: 390px;
  transform: translate(1rem, 3rem);
  position: absolute;
}

#fitbit-320x50 {
  left: 300px;
  top: 340px;
  transform: translate(1rem, 2rem);
  position: absolute;
}

#oracle-728x90 {
  left: 460px;
  top: 0px;
  transform: translate(2rem, 0);
  position: absolute;
}

#oracle-320x50 {
  left: 460px;
  top: 90px;
  transform: translate(2rem, 1rem);
  position: absolute;
}

#oracle-300x50 {
  left: 460px;
  top: 140px;
  transform: translate(2rem, 2rem);
  position: absolute;
}

#sky-300x250 {
  left: 160px;
  top: 0px;
  transform: translate(1rem, 0);
  position: absolute;
}

#sky-728x90 {
  left: 160px;
  top: 250px;
  transform: translate(1rem, 1rem);
  position: absolute;
}

.iframe-border {
  border: 1px solid black;
}

.screenshot {
  width: 616px;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: top;
}

.screenshot img {
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid black;
}

@media only screen and (max-width: 600px) {
  .aside-logo {
    margin: 40px 65px;
  }

  .page iframe {
    position: relative;
    top: 0px;
    left: 0px;
    transform: translate(0, 0);
  }

  .main {
    width: 300px;
    margin: auto;
  }

  .broadsheet-main,
  .broadsheet-header {
    width: auto;
    margin: 1rem;
    margin-top: 0;
  }

  #fitbit-300x600,
  #fitbit-300x250,
  #fitbit-300x50,
  #oracle-300x250,
  #oracle-300x600,
  #oracle-300x50,
  #facebook-300x250,
  #facebook-300x600,
  #fifa19-300x600,
  #fifa19-300x250,
  #sky-300x250 {
    display: inline-block;
    left: 0px;
    top: 0px;
    transform: none;
    position: relative;
  }

  #fitbit-970x250,
  #fitbit-728x90,
  #fitbit-320x50,
  #oracle-728x90,
  #oracle-320x50,
  #oracle-160x600,
  #facebook-728x90,
  #fifa19-970x250,
  #fifa19-970x66,
  #fifa19-728x90,
  #fifa19-320x50,
  #fifa19-160x600,
  #sky-728x90,
  #sky-160x600 {
    display: none;
  }

  .screenshot {
    width: 300px;
  }
}
